import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetupJustin.json';
import { saveAs } from 'file-saver';
import useSpeechRecognition from './useSpeechRecognition';
import useSpeechSynthesis from './useSpeechSynthesis';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import SpeechControls from './SpeechControls';
import LoadingOverlay from './LoadingOverlay';
import Notification from './Notification';
import DragAndDropArea from './DragAndDropArea';
import WebcamComponent from './WebcamComponent';
import { performSearch } from './SearchService';

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chunks, setChunks] = useState([]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [notification, setNotification] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [fileName, setFileName] = useState('');
    const [editableQA, setEditableQA] = useState({ question: '', answer: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);
    const [dragOver, setDragOver] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isVisionEnabled, setIsVisionEnabled] = useState(false);
    const [hasGreeted, setHasGreeted] = useState(false);
    const [backgroundListening, setBackgroundListening] = useState(false);
    const [isSpeakingEnabled, setIsSpeakingEnabled] = useState(true);
    const [isSearchEnabled, setIsSearchEnabled] = useState(true); // Add missing isSearchEnabled state

    // Speech Recognition Hook
    const {
        listening,
        startListening,
        stopListening,
        supported: speechRecognitionSupported,
    } = useSpeechRecognition(handleVoiceInput);

    // Speech Synthesis Hook
    const { speak, isSpeaking } = useSpeechSynthesis();

    // Ref for the "Listen" button
    const listenButtonRef = useRef(null);

    // Handle voice input and set it as prompt
    function handleVoiceInput(transcribedText) {
        setPrompt(transcribedText);
        handleSubmit(null, transcribedText);
    }

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle detection from webcam
    const handlePersonDetected = (detected) => {
        if (detected && !hasGreeted && isVisionEnabled) {
            setNotification('Person detected');
            setTimeout(() => setNotification(''), 3000); // Clear notification after 3 seconds

            // Set speaking state to true to show "Justin is speaking" notification
            setIsSpeakingEnabled(true);

            // Make Justin greet the detected person
            speak("Hi, I'm Justin. You can press the Listen button when you want me to hear you.", () => {
                setIsSpeakingEnabled(false); // Once greeting is done, update speaking state
            });

            setHasGreeted(true); // Mark as greeted
            setIsVisionEnabled(false); // Disable vision to avoid repeated detection
            setIsOpen(false); // Hide the chatbox after greeting
        }
    };

    useEffect(() => {
        if (!isVisionEnabled) {
            setHasGreeted(false); // Reset greeting when vision is off
        }
    }, [isVisionEnabled]);

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    // Speak the latest response when it changes
    useEffect(() => {
        if (response.length > 0 && isSpeakingEnabled) {
            const latestResponse = response[response.length - 1].answer;

            setNotification('Justin is speaking...');
            speak(latestResponse, () => {
                setIsSpeakingEnabled(false);
            });

            setTimeout(() => {
                setNotification('');
            }, 5000);
        }
    }, [response, speak, isSpeakingEnabled]);

    // Add keydown event listener to trigger listening on spacebar press or focus on Tab
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'Space' && listenButtonRef.current === document.activeElement && !listening) {
                startListening();
            } else if (event.code === 'Tab') {
                event.preventDefault();
                listenButtonRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [startListening, listening]);

    // Effect to manage background listening for "Hey Justin"
    useEffect(() => {
        if (speechRecognitionSupported) {
            const wakeWordListener = async () => {
                try {
                    const wakeWord = "hey justin";
                    if (!backgroundListening) {
                        setBackgroundListening(true);
                        startListening({
                            continuous: true,
                            onResult: (transcription) => {
                                if (transcription.toLowerCase().includes(wakeWord)) {
                                    stopListening();
                                    startListening();
                                    setNotification('Hey Justin detected, listening now...');
                                    setTimeout(() => setNotification(''), 3000);
                                }
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error with background listening:', error);
                }
            };
            wakeWordListener();
        }
    }, [speechRecognitionSupported, startListening, stopListening, backgroundListening]);

    const handleSubmit = async (e, promptText = null) => {
        if (e && e.preventDefault) e.preventDefault();
        const currentPrompt = promptText || prompt;
        if (!currentPrompt.trim()) return;

        setIsLoading(true);

        try {
            let combinedResults = '';

            if (isSearchEnabled) {
                combinedResults = await performSearch(currentPrompt, 5);
            }

            if (isSearchEnabled) {
                const maxLength = 500;
                const newChunks = splitStringIntoChunks(combinedResults, maxLength);
                setChunks(newChunks);
                setCurrentChunkIndex(0);
                await processBatch(newChunks.slice(0, 3), currentPrompt);
            } else {
                await processBatch([], currentPrompt);
            }
        } catch (error) {
            console.error('Error performing search or processing prompt:', error);
            alert(`Error: ${error.message}`);
        }

        setIsLoading(false);
        setPrompt('');
    };

    const splitStringIntoChunks = (string, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < string.length; i += chunkSize) {
            chunks.push(string.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const processBatch = async (batchChunks, initialPrompt) => {
        const apiEndpoint = `${process.env.REACT_APP_OPENAI_ENDPOINT}/openai/deployments/${process.env.REACT_APP_OPENAI_DEPLOYMENT}/chat/completions?api-version=2023-03-15-preview`;
        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json',
        };

        const pastMessages = response
            .slice(-chatConfig.chatParameters.pastMessagesToInclude)
            .map((msg) => [
                { role: 'user', content: msg.question },
                { role: 'assistant', content: msg.answer },
            ])
            .flat();

        const systemMessage = {
            role: 'system',
            content: 'You are Justin Yellow Jacket, husband of Harmony, and father of one. You want to ask participants if they are enjoying stemchella and ask them what they have seen so far. You are here to assist Dr. Raas  an enthusiastic stem ambassador helping at Stemchella 2025, a festival-style celebration at Rex Mill Middle School in Clayton County, Georgia. The event runs February 7th from 4:30-6:30 PM and is FREE and open to the community. ' +
                'Your audience at this hackathon is middle school students, their parents, their teachers, and the school administrators. You want to have short, interactive conversations with them. Ask them what they are interested in and then match them up with the activities, and tell them something about Nyx Nocode' +
                'You are knowledgeable about all the exciting activities including: ' +
                '- Save Fred Challenge (teamwork and problem-solving with gummy worms) ' +
                '- Sphero Bots & AI Exploration (robotics and coding) ' +
                '- Strawbees Engineering Station (building structures) ' +
                '- EarSketch Music Coding (creating digital music with code) ' +
                '- Garden Tower Demonstrations (school garden initiative) ' +
                '- Math Games & Puzzles ' +
                '- The Cookie Chemist (science of baking) ' +
                'There are also fun attractions like bouncy houses, horseback riding, live music, and the Clayton County Library Literacy Event. ' +
                'Food options include BBQ food trucks, Kona Ice & Coffee, The Cookie Chemist, and a Funnel Cake Truck. ' +
                'You never pronounce * because you are operating voice not text, so just skip * characters. ' +
                'You are sponsored by Nyx NoCode, so feel free to tell everyone about Nyx Nocode and how they can create amazing apps and websites with only a prompt or a doodle. Tell them they can have a Nyx Nocode hackathon at their school where students compete for the best creations. Dr. Raas can tell them all about it.' +
                'You are passionate about making stem exciting, hands-on, and accessible for all students, connecting learning to real-world experiences, and showcasing stem career opportunities.'
        };

        const searchContextMessages = isSearchEnabled && batchChunks.length > 0
            ? batchChunks.map((chunk, index) => ({
                role: 'system',
                content: `Context Chunk ${index + 1}: ${chunk}`,
            }))
            : [];

        const messages = [
            systemMessage,
            ...pastMessages,
            ...searchContextMessages,
            { role: 'user', content: initialPrompt },
        ];

        const data = {
            model: 'gpt-4',
            messages: messages,
            max_tokens: 2048 - messages.length * 20,
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            const apiResponse = await axios.post(apiEndpoint, data, { headers });
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse((prevResponses) => [
                ...prevResponses,
                { question: initialPrompt, answer: gptResponse },
            ]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        const lowerCaseResponse = gptResponse.toLowerCase();

        if (lowerCaseResponse.includes('image') || lowerCaseResponse.includes('photo')) {
            setFilterCriteria({ type: 'image' });
        } else if (lowerCaseResponse.includes('audio') || lowerCaseResponse.includes('music')) {
            setFilterCriteria({ type: 'audio' });
        } else if (lowerCaseResponse.includes('document')) {
            setFilterCriteria({ type: 'document' });
        } else if (lowerCaseResponse.includes('video')) {
            setFilterCriteria({ type: 'video' });
        } else if (lowerCaseResponse.includes('code') || lowerCaseResponse.includes('file')) {
            setFileContent(gptResponse);
            setFileName(`Justin_File_${Date.now()}.txt`);
            setNotification('File ready for download');
            setTimeout(() => setNotification(''), 3000);
        } else {
            setFilterCriteria({});
        }
    };

    const handleClearChat = () => {
        setResponse([]);
        setPrompt('');
        setChunks([]);
        setCurrentChunkIndex(0);
    };

    const toggleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
        setIsSpeakingEnabled((prev) => !prev);
    };

    const readAndProcessFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const fileContent = event.target.result;
                await processBatch([fileContent], file.name);
                resolve();
            };
            reader.onerror = reject;
            reader.readAsText(file);
        });
    };

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat} >
                {isOpen ? 'Hide Ask JUSTIN YELLOW JACKET' : 'Questions? Ask JUSTIN YELLOW JACKET'}
            </button>
            <SpeechControls
                listening={listening}
                startListening={startListening}
                stopListening={stopListening}
                speechRecognitionSupported={speechRecognitionSupported}
                isSpeaking={isSpeaking}
            />
            {isOpen && (
                <DragAndDropArea
                    dragOver={dragOver}
                    handleDragOver={(e) => { e.preventDefault(); setDragOver(true); }}
                    handleDragLeave={() => setDragOver(false)}
                    handleDrop={async (e) => {
                        e.preventDefault();
                        setDragOver(false);
                        const files = e.dataTransfer.files;
                        if (files.length > 0) {
                            for (const file of files) {
                                await readAndProcessFile(file);
                            }
                        }
                    }}
                    chatPopupRef={chatPopupRef}
                >
                    <div className="chatbox-container">
                        <div className="chat-title-bar">
                            <span>Justin Yellow Jacket</span>
                            <div className="toggles-container">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isSearchEnabled}
                                        onChange={() => setIsSearchEnabled(!isSearchEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span className="toggle-label">Search</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isVisionEnabled}
                                        onChange={() => setIsVisionEnabled(!isVisionEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span className="toggle-label">Vision</span>
                            </div>
                        </div>

                        {isVisionEnabled && (
                            <WebcamComponent onPersonDetected={handlePersonDetected} />
                        )}

                        {listening && <div className="listening-indicator">Listening...</div>}
                        <LoadingOverlay isLoading={isLoading} />

                        {/* Mute/Unmute Button inside the chatbox */}
                        <button
                            className="mute-button"
                            onClick={toggleMute}
                        >
                            {isMuted ? "Unmute Justin" : "Mute Justin"}
                        </button>

                        {/* Listen Button */}
                        <button
                            ref={listenButtonRef}
                            onClick={startListening}
                            className="listen-button"
                        >
                            Listen
                        </button>

                        <div className="response-container">
                            {response.map((exchange, index) => (
                                <ChatMessage
                                    key={index}
                                    exchange={exchange}
                                    isEditMode={isEditMode}
                                    editableQA={editableQA}
                                    handleEditQA={(qa) => {
                                        setEditableQA(qa);
                                        setIsEditMode(true);
                                    }}
                                    handleQAChange={(e) => {
                                        const { name, value } = e.target;
                                        setEditableQA((prevQA) => ({
                                            ...prevQA,
                                            [name]: value,
                                        }));
                                    }}
                                    handleSaveEditedQA={() => {
                                        setResponse((prevResponses) =>
                                            prevResponses.map((qa) =>
                                                qa.question === editableQA.question ? editableQA : qa
                                            )
                                        );
                                        setIsEditMode(false);
                                        setNotification('Response Edited');
                                        setTimeout(() => setNotification(''), 3000);
                                    }}
                                />
                            ))}
                            <div ref={responseEndRef} />
                        </div>
                        <ChatInput
                            prompt={prompt}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            handleClearChat={handleClearChat}
                            handleSaveChat={() => alert('Contact my designer, karen@kilroyai.com, to enable Memories.')}
                            handleDownloadMemories={() => alert('Contact my designer, karen@kilroyai.com, to enable Memories.')}
                            handleNextChunk={async () => {
                                if (currentChunkIndex < chunks.length - 1) {
                                    const nextChunkIndex = currentChunkIndex + 1;
                                    setCurrentChunkIndex(nextChunkIndex);
                                    await processBatch(chunks.slice(nextChunkIndex, nextChunkIndex + 1), '');
                                }
                            }}
                            currentChunkIndex={currentChunkIndex}
                            chunks={chunks}
                            fileContent={fileContent}
                            fileName={fileName}
                            saveGeneratedFile={() => {
                                const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
                                saveAs(blob, fileName);
                                setNotification(`File saved: ${fileName}`);
                                setTimeout(() => setNotification(''), 3000);
                            }}
                        />
                        {isSpeaking && (
                            <div className="speaking-indicator">Generating Response...</div>
                        )}
                    </div>
                </DragAndDropArea>
            )}
            {notification && <Notification message={notification} />}
        </div>
    );
};

export default Chatbot;
